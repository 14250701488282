.layout {
    background-size: "cover";
  }
  
  .container {
    max-width: 408px;
    margin: auto;
  }
  
  .title {
    text-align: center;
    color: #626262;
    font-size: 30px;
    letter-spacing: -0.04em;
  }
  